import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import LeadContent from '../components/leadContent/leadContent';
import ListingLevelSelectionContent from '../components/listingLevelSelection/listingLevelSelection';
import { Container, Row, Card, Col } from 'react-bootstrap';
import SEO from '../components/seo';

export default function Index() {
  const [step, setStep] = useState(0);
  const [lead, setLead] = useState<any>(null);

  const handleLeadComplete = (lead) => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    setStep(1);
    setLead(lead);
  }

  const handleListingSelectionComplete = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    setStep(2);
  }

  

  return (
      <Layout pageName='' siteName=''>
        <SEO title='PinkMoon Listing Registration' />
        {
          step === 2 && (
            <Container>
              <Row>
                <Col xs={12}>
                  <Card>
                    <h1>Thank You & Next Steps</h1>
                    <div style={{maxWidth: '750px', textAlign: 'center', margin: '0 auto'}}>
                      <p>Thank you for submitting your listing request. We try to fulfill the listing within 1 business day. You will hear from us very soon to show your listing and request any additional info. </p>
                      <p><strong>Have Questions?</strong></p>
                      <p>The quickest way to get a response is contacting us through our Facebook Page here: 
                        <a href="https://www.facebook.com/PinkMoonCoffee"  rel="nofollow" title="PinkMoon Coffee Facebook">https://www.facebook.com/PinkMoonCoffee</a></p>
                      <p><a style={{textDecoration: 'underline', color: '#d37'}} href="https://pinkmooncoffee.com" title="Pink Moon Coffee">Continue to PinkMoon Coffee</a> </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          )
        }
        {
          step === 1 && <ListingLevelSelectionContent onListingLevelSelectionComplete={handleListingSelectionComplete} lead={lead} />
        }
        {
          step === 0 && <LeadContent onCompleteLeadContent={handleLeadComplete} />
        }
      </Layout>
  )
}