import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, ModalBody } from 'react-bootstrap';
import './listingLevelSelection.scss';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { PaymentRequestButtonElement, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useMutation, gql } from '@apollo/client';


const CREATE_SUBSCRIPTION_MUTATION = gql`
    mutation onNewSubscriptionRequested($customerId: String!, $paymentMethodId: String!, $priceId: String!) {
        onNewSubscriptionRequested(customerId: $customerId, paymentMethodId: $paymentMethodId, priceId: $priceId)
    }
`;

const LISTING_PACKAGES = [
    {
        name: 'Basic Membership',
        description: 'Promote Your Business',
        bulletPoints: [
            'List your business on PinkMoon',
            'Get driving directions to your location',
            'Add phone number & contact details',
            'Add your online store or website',
            'Add your logo & branding',
            'Listing displays above verified and unverified listings',
            'Showcase basic level icon'
        ],
        termLengths: {
            1: {
                priceId: 'price_1HWAXuLlSuaRKOdjdHNVBv0q',
                monthlyPriceInCents: 1900,
            },
            12: {
                priceId: 'price_1HWmCuLlSuaRKOdjcS5Vp0Ts',
                monthlyPriceInCents: 1500
            }
        }
    },
    {
        name: 'Standard Membership',
        description: 'Show Off Your Products',
        bulletPoints: [
            'List your business on PinkMoon',
            'Get driving directions to your location',
            'Showcase phone number & contact info',
            'Showcase online store or website',
            'Showcase logo and branding',
            'Showcase your shop menu',
            'Showcase your coffee roast selection.',
            'Get requests for coffee ',
            'List your coffee  specialties',
            'Additional branding',
            'Get sampler box requests',
            'Placement above basic, verified and unverified listings.'
        ],
        termLengths: {
            1: {
                priceId: 'price_1HWAXKLlSuaRKOdj79CEfoRc',
                monthlyPriceInCents: 2900,
            },
            12: {
                priceId: 'price_1HWAXLLlSuaRKOdjg1VAvaiY',
                monthlyPriceInCents: 2300
            }
        }
    },
    {
        name: 'Premium Listing',
        description: 'Stand Out and Be First',
        bulletPoints: [
            'List your business on PinkMoon',
            'Get driving directions to your location',
            'Showcase phone number & contact info',
            'Showcase online store or website',
            'Showcase logo and branding',
            'Showcase your shop menu',
            'Showcase your coffee roast selection.',
            'Get requests for coffee ',
            'List your coffee  specialties',
            'Additional branding',
            'Get sampler box requests',
            'Placement above standard, basic, verified and unverified listings.',
            'Signature callouts and placements',
            'Icons notating premium listing',
            'Exclusive Pink bars in listings to stand out'],
        termLengths: {
            1: {
                priceId: 'price_1HWAWbLlSuaRKOdjZlKlBWMk',
                monthlyPriceInCents: 3900,
            },
            12: {
                priceId: 'price_1HWAWbLlSuaRKOdjDiBO2HUz',
                monthlyPriceInCents: 3100
            }
        }
    }
]

export default function ListingLevelSelectionContnet ({lead, onListingLevelSelectionComplete}) {
    const [termLength, setTermLength] = useState(12);
    const [checkoutTermLength, setCheckoutTermLength] = useState(12);
    const stripe = useStripe();
    const elements = useElements();
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [createSubscription] = useMutation(CREATE_SUBSCRIPTION_MUTATION);
    const [levelDetails, setLevelDetails] = useState(LISTING_PACKAGES[1]);
    const [paymentError, setPaymentError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  

    const requestPayment = (level) => {
        setLevelDetails(level);
        setCheckoutTermLength(12);
        setShowPaymentForm(true);
    }

    const handleSubmitPayment = async () => {
        // Block native form submission.
        event.preventDefault();
        setIsLoading(true);
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[createPaymentMethod error]', error);
            setPaymentError(error.message);
            setIsLoading(false);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            const paymentMethodId = paymentMethod.id;
            // Create the subscription
            const {data, errors} = await createSubscription({
                variables: {
                    customerId: lead.customer.id,
                    paymentMethodId,
                    priceId: levelDetails.termLengths[termLength].priceId
                }
            });

            if (errors) {
                // Show error to your customer (e.g., insufficient funds)
                console.log(errors);
                setIsLoading(false);
            } else {
                // The payment has been processed!
                console.log(data);
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                onListingLevelSelectionComplete(data);
            }
        }
    }

    return (
        <Container className='listing-level'>
            <Modal show={showPaymentForm}>
                <ModalHeader className='listing-level'>
                    <h4>{levelDetails.name}</h4>
                    <p><strong>${levelDetails.termLengths[checkoutTermLength].monthlyPriceInCents / 100} / month</strong><br />
                    {
                        typeof window !== 'undefined' && (
                            <span>
                                Annually <BootstrapSwitchButton
                                    offlabel=' '
                                    size='xs'
                                    onlabel=' '
                                    style='w-30'
                                    onChange={() => {setCheckoutTermLength(checkoutTermLength === 1 ? 12 : 1)}} /> Monthly
                                </span>
                        )
                    }</p>
                </ModalHeader>
                <ModalBody>
                    <h5 style={{display: 'block', marginBottom: '1.5em'}}>Today's Total: ${levelDetails.termLengths[checkoutTermLength].monthlyPriceInCents * checkoutTermLength / 100}</h5>
                    <CardElement /> 
                    <div style={{marginTop: '3em'}}>
                        <Button disabled={isLoading} onClick={handleSubmitPayment} className='btn btn-primary'>PAY</Button>
                        <a onClick={() => {setShowPaymentForm(false); setPaymentError('')}} className='btn'>CANCEL</a>
                    </div>
                    <p style={{color: '#a00'}}>{paymentError}. {paymentError !== '' && 'Please Try again'}</p>
                </ModalBody>
            </Modal>
          <Row>
            <Col xs={12}>
                <Card>
                    <h1 style={{marginBottom: 0, paddingBottom: 0}}>Choose Your Listing Level</h1>
                    <p style={{fontSize: '.85em', textAlign: 'center', color: '#333', maxWidth: '760px', margin: '0 auto'}}>Thanks for requesting your free listing. Here's our upgraded listings that provide additional exposure, details & features with prices lower than a 1lb of coffee. You can sign up now or anytime in the future.</p>
                </Card>
            </Col>
          </Row>
          <Row className='justify-content-center' style={{padding: '0 0 0', marginTop: '1em'}}>
              <Col xs={12} md={7} lg={3} style={{marginBottom: '1em'}}>
                  <div style={{backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '.75em 1em'}}>
                    <span>Billed: {termLength === 1 ? 'Monthly' : 'Annually'} &nbsp; &nbsp;</span>
                    {
                        typeof window !== 'undefined' && (
                            <BootstrapSwitchButton
                                offlabel=' '
                                size='xs'
                                onlabel=' '
                                style='w-30'
                                onChange={() => {setTermLength(termLength === 1 ? 12 : 1)}} />
                        )
                    }
                    
                  </div>
              </Col>
          </Row>
          <Row>
              {
                  LISTING_PACKAGES.map(p => (
                      <Col lg={4}>
                          <Card onClick={() => {requestPayment(p)}}>
                            <div style={{textAlign: 'center', marginBottom: '.75em'}} className="cardHeader">
                                <h4 style={{marginBottom: '.35em'}}>{p.name}</h4>
                                <p style={{marginBottom: '.5em'}}>{p.description}</p>
                                <p style={{margin: 0, color: '#d37'}}><strong>${p.termLengths[termLength].monthlyPriceInCents / 100} / month</strong></p>
                            </div>
                            <div className='cardBody'>
                                <ul style={{fontSize: '.85em', height: '350px'}}>
                                    { p.bulletPoints.map(bp => <li>{bp}</li>) }
                                </ul>
                                <div style={{textAlign: 'center'}}>
                                    <a className='btn btn-outline-primary btn-block'>SELECT</a>
                                </div>
                            </div>
                          </Card>
                      </Col>
                  ))
              }
          </Row>
          <Row style={{marginTop: '2em'}}>
            <Col xs={12}>
              <p style={{textAlign: 'center', fontSize: '.9em'}}>
                <a onClick={() => {onListingLevelSelectionComplete(null)}} style={{cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline'}}>No thanks, I want to continue with my free verified listing for now.</a>
              </p>
            </Col>
          </Row>
        </Container>
    )
}