import React from 'react';
import { FormGroup, Form, Row, Button, Col } from 'react-bootstrap';
import firebase from "gatsby-plugin-firebase";


export default function RegistrationForm() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');

    const registerUser = () => {
        console.log(email, password);
        firebase.auth().createUserWithEmailAndPassword(email, password).then(d => {
            console.log(d);
        }).catch(error => {
            console.log(error);
            setError(error.message);
        })
    }
    
    return (
        <Form>
            <FormGroup>
            <Form.Label>Email:</Form.Label>
            <Form.Control name="email" type="email" onKeyUp={(e) => {setEmail(e.target.value)}}  />
            </FormGroup>
            <FormGroup>
            <Form.Label>Password:</Form.Label>
            <Form.Control name="password" type="password" onKeyUp={(e) => {setPassword(e.target.value)}}  />
            </FormGroup>
            <Row>
                <Col xs={12}>
                    <Button onClick={registerUser} disabled={email === '' || password === ''} className='btn btn-primary'>SIGN UP</Button>
                    <p style={{color: '#d00'}}>{error}</p>
                </Col>
            </Row>
        </Form>
    )
}