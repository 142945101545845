import React, {useState} from 'react';
import {Form, Row, Col, FormGroup, Button, Card} from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "gatsby-plugin-firebase";


const LEAD_MUTATION = gql`
mutation onNewLeadSubmit($name: String!, $email: String!, $company: String, $phone: String, $city: String, $region: String, $url: String, $userID: String) {
    onNewLeadSubmit(name: $name, email: $email, company: $company, city: $city, region: $region, phone: $phone, createCustomer: true, url: $url, userID: $userID) {
        _key,
        customer {
            _key,
            id
        }
    }
}
`


export default function LeadForm({onNewLeadSubmitted}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [url, setUrl] = useState('');
    const [submitLead,  { data }] = useMutation(LEAD_MUTATION);
    const [isLoading, setIsLoading] = useState(false);
    const [user, loading, error] = useAuthState(typeof window !== 'undefined' ? firebase.auth() : {});
    const userID = user ? user.uid : '';
      
    const onCanSubmit = () => {
        const canSubmit = name !== '' && email !== '' && company !== '';

        return canSubmit;
    }

    const onSubmitLead = (evt) => {
        evt.preventDefault();
      setIsLoading(true);
        submitLead({variables: {
            name,
            email,
            phone,
            company,
            city,
            region,
            url,
            userID
        }}).then(result => {
            if (onNewLeadSubmitted) {
                onNewLeadSubmitted(result.data.onNewLeadSubmit);
            }
        }).catch(err => {
          console.log(err);
        });
    }

    if (data && data.onNewLeadSubmit) {
        return (
            <Card>
                <p><strong>Thank you for submitting your request to get listed. A representative will reach out to you within one business day to help get you started. Thank you and we look forward to partnering with you.</strong></p>
            </Card>
        )
    }


    return (    
        <Form name="platform_lead_form"  onSubmit={onSubmitLead}>
            <Row style={{margin: '1.5em 0'}}>
                <Col lg={6}>
                    <FormGroup>
                        <Form.Label>Your Name *:</Form.Label>
                        <Form.Control name="name" onKeyUp={(evt) => {setName(evt.target.value)}} type="text" placeholder="Jane Doe" />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Form.Label>Company Name *:</Form.Label>
                        <Form.Control name="company" onKeyUp={(evt) => {setCompany(evt.target.value)}} type="text" placeholder="My Company Name" />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{margin: '1.5em 0'}}>
                <Col lg={4}>
                    <FormGroup>
                        <Form.Label>Email *:</Form.Label>
                        <Form.Control name="email" onKeyUp={(evt) => {setEmail(evt.target.value)}} type="email" placeholder="my@email.com" />
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control name="phone" onKeyUp={(evt) => {setPhone(evt.target.value)}} type="phone" placeholder="(888)333-3333" />
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <Form.Label>Company Website:</Form.Label>
                        <Form.Control name="website" onKeyUp={(evt) => {setUrl(evt.target.value)}} type="text" placeholder="mysite.com" />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{margin: '1.5em 0'}}>
                <Col xs={12}>
                    <FormGroup>
                        <Form.Label>Company Street Address:</Form.Label>
                        <Form.Control name="address" onKeyUp={(evt) => {setCity(evt.target.value)}} type="text" placeholder="My City" />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{margin: '1.5em 0'}}>
                <Col lg={6}>
                    <FormGroup>
                        <Form.Label>Company City *:</Form.Label>
                        <Form.Control name="city" onKeyUp={(evt) => {setCity(evt.target.value)}} type="text" placeholder="My City" />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Form.Label>Company State *:</Form.Label>
                        <Form.Control name="state" onKeyUp={(evt) => {setRegion(evt.target.value)}} type="text" placeholder="Wisconsin" />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{margin: '1.5em 0'}} className='justify-content-center'>
                <Col lg={4} style={{textAlign: 'center'}}>
                    <input type='submit' disabled={!onCanSubmit() || isLoading} className='btn btn-primary' value='LIST YOUR BUSINESS' />
                    {isLoading && <p style={{color: '#d37'}}>one moment while we process your request...</p>}
                </Col>
            </Row>
        </Form>
    )
}