import React, { useState } from 'react';
import {Container, Row, Col, Card, Form, FormGroup} from 'react-bootstrap';
import LeadForm from '../leadForm/leadForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar, faCheck, faArrowDown} from'@fortawesome/free-solid-svg-icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "gatsby-plugin-firebase";
import RegistrationForm from '../registrationForm/registrationForm';


export default function LeadContent({onCompleteLeadContent}) {
  const [user, loading, error] = useAuthState(typeof window !== 'undefined' ? firebase.auth() : {});
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');

    return (
        <Container>
              <Row>
                <Col xs="12">
                  <h1>List Your Coffee Business on PinkMoon For Free</h1>  
                </Col>
              </Row>
              {
                user && (
                  <div>
                    <Row className='justify-content-center'>
                      <Col lg={10}>
                        <Card style={{padding: '0 2em 2em 2em'}}>
                          <h4>Step 2: Add Your Company Info</h4>
                            <LeadForm 
                                  onNewLeadSubmitted={(lead) => {onCompleteLeadContent ? onCompleteLeadContent(lead) : console.error("Cannot complete lead content because missing function")}} />
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )
              }
              {
                !user && (
                  <Row>
                    <Col xs={12} md={6}>
                        <h3 style={{color: '#fff', fontSize: '1.35em', backgroundColor: '#d37', padding: "1em 1em .1em"}}>You Focus on Coffee. We Deliver Customers.</h3>
                        <div><img src="https://pinkmoonassets.nyc3.cdn.digitaloceanspaces.com/checkout.jpg" alt="Coffee Shop Online" style={{maxWidth: '100%'}} /></div>
                        
                    </Col>
                    <Col xs={12} md={6}>
                      <Card>
                        {
                            /** USER DOES NOT EXIST */
                            !user && (
                              <div>
                                <h4>Step 1: Create a Free Business Account</h4>
                                <RegistrationForm />
                              </div>)
                          }
                      </Card>
                    </Col>
                  </Row>
                )
              }
              {
                !user && (
                  <div>
                    <Row style={{marginTop: '2em'}}>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} sm={2} md={3}>
                                <h4 className={'benefit-points'}>Boost Your Brand</h4>
                                <p>
                                    Elevate your coffee brand through our community for coffee fanatics. Sit back while we bring customers to you through geo-location targeting &amp; advanced search algorithms. We'll give you the boost of large chains while you get to focus on perfecting your coffee.
                                </p>
                            </Col>
                            <Col xs={12} sm={2} md={3}>
                                <h4 className={'benefit-points'}>Highlight Your Roasts</h4>
                                <p>
                                    Add your top roasts &amp; menu for little more than a price of a little. Your profile allows you to distinguish yourself in many ways other than your brilliant coffee. Allow dogs in your shop? Have parking and WiFi? We’ll highlight what makes you special.
                                </p>
                            </Col>
                            <Col xs={12} sm={2} md={3}>
                                <h4 className={'benefit-points'}>Connect with Customers</h4>
                                <p>
                                    A Pink Moon Pro account allows you to connect directly with your customers. Not only including social media outlets, you have the option of blasting announcements directly on your profile for all customers or the ability to respond directly to single customers from your messaging dashboard.
                                </p>
                            </Col>
                            <Col xs={12} sm={2} md={3}>
                                <h4 className={'benefit-points'}>Grow Your Business</h4>
                                <p>
                                    With a reach of 100,000+ members across our social and web platform growing at a rate of 2,000+ new members per month we are proud to be able to offer a unique experience to help you grow your coffee business. In addition to our free listing offer we have several options to get your business in front of fellow coffee fanatics.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <p style={{textAlign: 'center', padding: '2em  0 1em', fontSize: '1.2em'}}>
                          <strong>Join Other Coffee Companies Using PinkMoon to Market Their Business.</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '2em'}} className='justify-content-center'>
                    <Col lg={8}>
                      <Card style={{padding: '1em 4em'}}>
                          <h4>Step 1: Create a Free Business Account</h4>
                        {
                          /** USER DOES NOT EXIST */
                          !user && <RegistrationForm />
                        }
                        {
                          /** USER EXISTS */
                          user && 
                          <LeadForm 
                                onNewLeadSubmitted={(lead) => {onCompleteLeadContent ? onCompleteLeadContent(lead) : console.error("Cannot complete lead content because missing function")}} />
                        }
                      </Card>
                    </Col>
                  </Row>
                  </div>
                )
              }
                
                {
                  /**
                   * 
                <Row style={{marginTop: '3em'}}>
                    <Col xs={12}>
                        <h3 className={'benefits'} style={{textAlign: 'center'}}>BUILD LOYAL COFFEE CUSTOMERS</h3>
                    </Col>
                    <Col xs={12}>
                      <p style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>We believe in independent coffee companies like you. We are driven to empower you to have a successful business. Selling 1 roast or cup of coffee is fine the first time but it doesn't pay the electricity bill. We are laser focused on connecting you with loyal customers that buy from you time and time again.</p>
                    </Col>
                </Row>
                <Row style={{marginTop: '3em'}}>
                  <Col xs={12}>
                    <h3 style={{textAlign: 'center'}} className='benefits'>HOW IT WORKS</h3>
                  </Col>
                </Row>
                <Row style={{textAlign: 'center'}}>
                  <Col style={{marginBottom: '2em'}} lg={12}>
                    <FontAwesomeIcon style={{color: '#999', fontSize: '2em', marginBottom: '1em'}} icon={faStar} />
                    <h4>List Your Coffee Business</h4>
                  </Col>
                  <Col style={{marginBottom: '2em'}} lg={12}>
                    <FontAwesomeIcon style={{color: '#999', fontSize: '2em', marginBottom: '1em'}}  icon={faArrowDown} />
                    <h4>We Promote You In Our Coffee Fanatics Community</h4>
                  </Col>
                  <Col style={{marginBottom: '2em'}} lg={12}>
                    <FontAwesomeIcon style={{color: '#999', fontSize: '2em', marginBottom: '1em'}}  icon={faArrowDown} />
                    <h4>Coffee Fans Show Interest in Your Coffee</h4>
                  </Col>
                  <Col style={{marginBottom: '2em'}} lg={12}>
                    <FontAwesomeIcon style={{color: '#999', fontSize: '2em', marginBottom: '1em'}}  icon={faCheck} />
                    <h4>We Connect Coffee Fans With Your Business</h4>
                  </Col>
                </Row>
                   */
                }
            </Container>
       
    )
}